* {
  margin: 0;
  padding: 0;
  font-family: apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
}

.success__background {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  color: black;
  padding: 50px;
  text-align: center;
}

.success__container img {
  width: 300px;
  height: 300px;
  margin-bottom: 50px;
}

.success__message {
  font-size: 1.7rem;
  line-height: 2.3rem;
}

.downloadUrl__container {
  margin-top: 50px;
  font-size: 1.4rem;
}
