input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App {
  background-color: #333;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App h1 {
  color: white;
  margin-right: 25px;
  font-size: 2.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
}

.required_field,
.invalid_input {
  color: red;
  margin: 0;
  display: none;
  width: 27%;
  margin-top: 15px;
}

.required_field h1,
.invalid_input h1 {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 2px 7px;
  border-radius: 2px;
  border: 1px solid red;
  padding: 6px;
  text-align: center;
}

.required_field.show,
.invalid_input.show {
  display: block;
}

.main__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  height: 90vh;
}

.sub__container {
  height: 80%;
}

.image__container {
  height: 120px;
  margin-bottom: 34px;
  display: flex;
  justify-content: center;
}

.image__container img {
  height: 100%;
}

.stage__row {
  margin-bottom: 35px;
}

.progress__stage {
  margin-bottom: 6px;
}
.progress__stage ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  width: 100%;
  margin: auto;
}

.li__div,
.li__div:first-child {
  display: flex;
  justify-content: center;
  height: 30px;
  width: 25px;
  border: 2px solid rgb(192, 192, 192);
  color: rgb(192, 192, 192);
  font-size: 0.8rem;
  line-height: 27.5px;
  font-weight: lighter;
  margin-bottom: 10px;
  transition: 0.4s ease;
}

.progress__bar {
  width: 100%;
  height: 4px;
  background-color: rgb(231, 231, 231);
}

.progress {
  height: 100%;
  width: 5%;
  background-color: #555;
  transition: 0.4s ease;
}

.info-entry__container {
  display: flex;
  padding: 30px;
  width: 895px;
  min-height: 160px;
  max-width: 895px;
  max-height: 450px;
  background: rgb(250, 250, 250);
  border: 1px solid rgb(207, 207, 207);
}

.stage__number {
  padding: 5px 15px;
  font-size: 0.7rem;
}

.stage__number span {
  background: #555;
  border-radius: 50%;
  color: white;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.info__entry {
  width: 100%;
  padding: 3px 15px;
}

.question__container {
  display: flex;
  color: #333;
}

.question__asterik {
  color: rgb(204, 0, 0);
  margin-left: 15px;
  font-size: 13px;
}

.info__title {
  font-weight: 500;
  font-size: 1.2rem;
}

.info__title span {
  color: rgb(204, 0, 0);
  margin-left: 15px;
  font-size: 13px;
  vertical-align: top;
}

.info__dropdown {
  border: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.4); */
  background: rgb(250, 250, 250);
  font-size: 1.2rem;
  color: grey;
  padding: 10px 50px 10px 0;
  padding: 12px 10px 10px 1px;
  margin: 0px 0 10px 0;
}

.info__dropdown select {
  border: none;
  background: rgb(250, 250, 250);
  font-size: 1.2rem;
  color: grey;
  padding-right: 90px;
  height: 35px;
  width: 100%;
}

.text__input,
.email__input,
.phone__input,
.date__input {
  background: none;
  font-size: 1.2rem;
  color: rgb(92, 92, 92);
  border: none;
  padding: 5px;
  width: 100%;
}

.date__input {
  width: 21%;
}

.text__input:focus,
.phone__input:focus,
.email__input:focus {
  border: none;
  outline: none;
}

.chcekbox__container {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  padding: 1px;
}

input[type="checkbox"] {
  transform: scale(1.5);
  cursor: pointer;
}

.selector_input,
.country__select {
  cursor: pointer;
}

.checkbox__label {
  display: block;
  position: relative;
}

.checkbox_text,
.radio_text {
  margin: 0 30px 0 10px;
  font-size: 1.2rem;
  color: black;
}

.address__container input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
}

.address__container input:focus {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.button__div {
  margin: 10px 0px;
  width: 100%;
}

button {
  padding: 10px 52px;
  margin-top: 10px;
  font-size: 1rem;
  cursor: pointer;
}

button:disabled {
  display: none;
}

.btn-container-column {
  display: flex;
  flex-direction: column;
}

.done__button {
  float: right;
}

.done__button:hover {
  cursor: pointer;
  background-color: #333;
  color: #ffffff;
  transform: scale(1.1);
}

button.done__button:disabled {
  background: #333;
  color: #ffffff;
  cursor: not-allowed;
  display: block;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.prev_button {
  font-size: 1rem;
  float: left;
  padding: 10px 39px;
  transition: transform 0.2s;
}

.prev_button:hover {
  cursor: pointer;
  background-color: #333;
  color: #ffffff;
  transform: scale(1.1);
}

.next_button {
  float: right;
  transition: transform 0.2s;
}

.next_button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.modal {
  display: none;
  position: absolute;
  left: 30%;
  top: 35%;
  /* background: #333;  */
  background: white;
  /* color: white; */
  color: black;
  /* width: 60%;
  height: 50%; */
  width: 40%;
  height: 20%;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9); */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.modal.show {
  display: flex;
}

.modal__content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background: lightcoral; */
  border-radius: 1rem;
}

.close_icon {
  position: absolute;
  font-size: 17px;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.close_icon:hover {
  color: #c4c4c4;
}

.modal_error {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 100%;
  padding: 20px;
}

.warning_icon {
  color: rgb(190, 0, 0);
  font-size: 60px;
  /* width: 20px; */
}

.modal_error_message {
  width: 70%;
  margin-left: 40px;
  font-size: 1.2rem;
}

.try_again_btn {
  background: none;
  /* color: white; */
  color: black;
  /* border: 1px solid red; */
  border: 1px solid rgb(116, 116, 116);
  border-radius: 2px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 20px;
  transition: 0.4s ease;
  margin: 0;
}

.try_again_btn:hover {
  /* background: #444;  */
  background: rgba(0, 0, 0, 0.1);
  /* background: rgba(0, 172, 196, 0.3); */
}

.modal_overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  z-index: 1;
}

.modal_overlay:hover {
  display: block;
}

.modal_overlay.show {
  display: block;
}

.download__button {
  margin-top: 5rem;
  font-size: 1.1rem;
}

.text__input {
  background-color: #fff;
}

.spinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner__container > svg {
  margin: 32px auto;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .main__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-height: 740px;
  }

  .sub__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .info__dropdown {
    font-size: 1rem;
    padding: 8px 10px;
  }

  .info-entry__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    max-width: 100%;
    min-height: auto;
    max-height: none;
  }

  .info__title {
    font-size: 1rem;
  }

  button {
    font-size: 0.8rem;
  }

  .button__div {
    margin-bottom: 8px;
  }

  .button__div .prev_button {
    font-size: 0.8rem;
  }

  .button__div .next_button {
    font-size: 0.8rem;
  }
}
